@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0 20vw;
  background-color: #fff;
}

/* 画面幅900px以下の場合に適用 */
@media (max-width: 900px) {
  body {
    padding: 0;
  }
}
